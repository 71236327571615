import { gql } from "@apollo/client";

const GET_BLOG_LIST = gql`
  query getBlogList(
    $category: String
    $limit: Int
    $start: Int
    $locale: String
  ) {
    blogs(
      sort: "updated_at:desc"
      where: { blog_type: { name: $category } }
      limit: $limit
      start: $start
      locale: $locale
    ) {
      id
      updated_at
      title
      description
      ERT
      blog_type {
        name
      }
      feature_image {
        alternativeText
        url
      }
    }
  }
`;

const GET_BLOG_TYPES = gql`
  query getBlogTypes($locale: String) {
    blogTypes(locale: $locale) {
      name
    }
  }
`;

const GET_BLOG = gql`
  query getBlog($id: ID!) {
    blog(id: $id) {
      title
      author
      description
      detail
      published_at
      blog_type {
        name
      }
      ERT
      feature_image {
        alternativeText
        url
      }
    }
  }
`;

const SEARCH_BLOGS_QUERY = gql`
  query SearchBlogs($query: String!, $locale: String) {
    blogs(where: { title_contains: $query }, locale: $locale) {
      id
      title
      description
    }
  }
`;

export { GET_BLOG_LIST, GET_BLOG_TYPES, GET_BLOG, SEARCH_BLOGS_QUERY };

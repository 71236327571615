import logo from "assets/nexiiot-white.svg";
import useUrlFormat from "hooks/useUrlFormat";
import { Link } from "react-router-dom";
import ExternalLinkIcon from "assets/home/externalLinkIcon";

export const BlogCard = ({ item, index, search, isMedium }) => {
  const urlTitle = useUrlFormat(item.title);

  const handleImageError = (event) => {
    event.target.onerror = null; // to prevent infinite loop
    event.target.src = logo; // set the src to the imported logo image
  };

  if (search) {
    return (
      <Link
        to={!isMedium ? `${urlTitle}` : item.guid}
        target={isMedium ? "_blank" : "_self"}
        rel="noopener noreferrer"
        state={{ id: item.id }}
      >
        <div className="border border-gray-200 rounded-xl p-4 hover:bg-light_blue flex justify-between w-full gap-4">
          <div>
            <p className="font-medium text-lg">{item.title}</p>
            <p className="line-clamp-3 text-gray-500">{item.description}</p>
          </div>

          {isMedium && (
            <div className="w-fit">
              <ExternalLinkIcon size={18} />
            </div>
          )}
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={!isMedium ? `${urlTitle}` : item.guid}
      target={isMedium ? "_blank" : "_self"}
      rel="noopener noreferrer"
      state={{ id: item.id }}
    >
      {/* feature_image */}
      <div className="w-full h-fit bg-gradient-to-t from-purple-200 to-white">
        <img
          className={`w-full object-contain aspect-[89/31] ${
            item.feature_image?.url || item.image ? "" : ""
          }`}
          src={
            !isMedium
              ? `${process.env.REACT_APP_STRAPI_URL}${item.feature_image?.url}`
              : item.image
          }
          onError={handleImageError} // handle image error
          alt={item.title} // add alt text for accessibility
        />
      </div>

      <div className="p-5">
        {/* type - ert */}
        <div className="flex items-center justify-between uppercase text-gray-500 text-xs mb-2">
          <span>{isMedium ? "Medium" : item?.blog_type?.name}</span>
          <span>{item?.ERT}</span>
          {isMedium && <ExternalLinkIcon size={18} />}
        </div>
        {/* name */}
        <p className="font-medium text-2xl">{item.title}</p>
        {/* description */}
        <p className="line-clamp-3 text-gray-500">{item.description}</p>
      </div>
    </Link>
  );
};

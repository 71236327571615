import { icons } from "assets/social";
import logo from "assets/nexiiot-icon.svg";
import { useQuery } from "@apollo/client";
import { GET_FOOTER_CONTENT } from "utils/apollo/graphql/layout";
import defaultData from "../../data/defaultData.json";
import { PhoneIcon, EnvelopeOpenIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

const Footer = () => {
  const { loading, data } = useQuery(GET_FOOTER_CONTENT, { lazy: true });
  const defaultFooter = defaultData.footer;

  if (loading) {
    return <LoadingFooter />;
  }

  const contact = data?.contact || defaultFooter.contact;
  const socialMedia =
    data?.socialMedia?.social || defaultFooter.socialMedia.social;

  return (
    <footer className="bg-primary" id="footer">
      <div className="mx-auto max-w-screen-xl p-4 lg:p-8">
        <div className="md:flex justify-center lg:justify-between">
          <div className="grid grid-cols-1 gap-8 lg:gap-6 lg:grid-cols-4">
            <LogoSection />
            <AddressSection address={contact.address} />
            <ContactDetailsSection
              phone={contact.phone_number}
              email={contact.email}
            />
            <ReportSection report={contact.osticket} />
          </div>
        </div>
        <FooterBottom
          currentYear={new Date().getFullYear()}
          socialMedia={socialMedia}
        />
      </div>
    </footer>
  );
};

const LoadingFooter = () => {
  const { t } = useTranslation();
  return <div className="text-center py-4">{t("loading")}...</div>;
};

const LogoSection = () => (
  <div className="mb-6 lg:mb-0">
    <Link to="/" className="flex items-center">
      <img src={logo} className="h-8 px-3 rounded-full" alt="nexiiot_logo" />
      <span className="text-3xl font-medium text-white ml-2">NEXIIOT</span>
    </Link>
  </div>
);

const AddressSection = ({ address }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-4 text-md font-medium text-white">{t("address")}</h2>
      <p className="text-warm_white font-light">{address}</p>
    </div>
  );
};

const ContactDetailsSection = ({ phone, email }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-4 text-md font-medium text-white">{t("contact")}</h2>
      {phone && (
        <p className="text-warm_white font-light mb-4">
          <PhoneIcon className="w-5 h-5 inline me-2" />
          {phone}
        </p>
      )}
      {email && (
        <p className="text-warm_white font-light mb-4">
          <EnvelopeOpenIcon className="w-5 h-5 inline me-2" />
          {email}
        </p>
      )}
    </div>
  );
};

const ReportSection = ({ report }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="mb-3 text-md font-medium text-white">{t("needHelp")}</h2>
      <div className="flex items-center text-white font-light border-b-[1px] border-white w-fit">
        <Link
          to={report}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1"
        >
          {t("reportIssue")}
          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </Link>
      </div>
    </div>
  );
};

const FooterBottom = ({ currentYear, socialMedia }) => {
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row gap-4 items-center justify-between mt-8 lg:mt-10 font-light">
      <div className="flex items-center space-x-4">
        <span className="text-warm_white text-sm">© NEXPIE {currentYear}</span>
        <hr className="bg-white w-px h-5 mx-2" />
        <Link
          to="https://nexpie.com/privacypolicy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="text-warm_white text-sm"
        >
          {t("privacyPolicy")}
        </Link>
        <hr className="bg-white w-px h-5 mx-2" />
        <Link to={`/${lang}/terms-of-use`} className="text-warm_white text-sm">
          {t("termsOfUse")}
        </Link>
      </div>
      <div className="flex space-x-2">
        {socialMedia
          .filter((social) => social.link && social.link !== "")
          .map((social, index) => (
            <a
              href={social.link}
              className="text-warm_white"
              key={`social_${index}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="rounded-full bg-white/10 p-3 hover:bg-primaryHover">
                {icons[social.name]}
              </div>
            </a>
          ))}
      </div>
    </div>
  );
};

export default Footer;

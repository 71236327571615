import { gql } from "@apollo/client";

const GET_FOOTER_CONTENT = gql`
  query contact($locale: String) {
    contact(locale: $locale) {
      address
      phone_number
      email
      osticket
    }
    socialMedia {
      social {
        name
        link
      }
    }
  }
`;

export { GET_FOOTER_CONTENT };

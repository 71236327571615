import { useQuery } from "@apollo/client";
import { Layout } from "components/layout";
import { useLocation } from "react-router-dom";
import { GET_BLOG } from "utils/apollo/graphql/blog";
import parse from "html-react-parser";
import "./Blog.css";
import Avatar from "components/Avatar";
import { format } from "date-fns";
import Loading from "components/Loading";
import useReadingTimeFormatter from "hooks/useReadingTimeFormatter";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { useEffect, useState } from "react";
import { isNonEmptyArray } from "@apollo/client/utilities";
import { useTranslation } from "react-i18next";
import NotFound from "components/NotFound";

export const Blog = () => {
  const [isFloating, setIsFloating] = useState(false);
  const location = useLocation();
  const fullPath = `${window.location.origin + location.pathname}?id=${
    location.state.id
  }`;
  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get("id");
  const { t } = useTranslation();

  const ID = location.state.id || paramId;

  const { loading, data, error } = useQuery(GET_BLOG, {
    variables: {
      id: ID,
    },
  });

  const DATA = data?.blog;

  const formattedReadingTime = useReadingTimeFormatter(DATA?.ERT);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.getElementById("footer");
      const footerTop = footer.offsetTop;
      const windowHeight = window.innerHeight;
      if (window.pageYOffset + windowHeight >= footerTop + 60) {
        setIsFloating(false);
      } else {
        setIsFloating(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout className="relative">
      <div className="mt-24 min-h-screen h-full px-4">
        <Loading />
        {loading ? (
          <Loading />
        ) : error ? (
          <div>{t("contentCantLoad")}</div>
        ) : !DATA ? (
          <NotFound />
        ) : (
          <div className="ck-content max-w-screen-md mx-auto">
            {/* ERT */}
            <p className="text-gray-600">{formattedReadingTime}</p>
            {/* type */}
            <div className="py-1 px-3 rounded-lg bg-gray-200 w-fit text-xs capitalize">
              {DATA?.blog_type?.name}
            </div>
            {/* Title */}
            <h1 className="font-bold my-1">{DATA.title}</h1>
            {/* description */}
            <p className="">{DATA.description}</p>

            <div className="mt-5 flex items-center gap-2">
              <Avatar size={10} name={DATA.author} />
              <div className="text-xs">
                {/* author */}
                <div className="text-primary">{DATA.author}</div>
                {/* published_at */}
                <div>{format(new Date(DATA.published_at), "PP")}</div>
              </div>
            </div>

            {/* divider */}
            <div className="border-b w-full my-5" />

            {/* feature image */}
            {isNonEmptyArray(DATA.feature_image) && DATA.feature_image?.url ? (
              <img
                src={`${process.env.REACT_APP_STRAPI_URL}${DATA.feature_image?.url}`}
                className="mt-10 mb-16"
                alt={`feature_img_${DATA.title}`}
              />
            ) : (
              <></>
            )}

            {/* content */}
            {DATA.detail ? (
              parse(DATA.detail, {
                replace: (domNode) => {
                  if (domNode.name === "img") {
                    if (!domNode.attribs.src.includes("http")) {
                      return (
                        <img
                          src={`${process.env.REACT_APP_STRAPI_URL}${domNode.attribs.src}`}
                          alt={domNode.attribs.alt}
                        />
                      );
                    }
                  }
                },
              })
            ) : (
              <></>
            )}
            {}

            {/* share */}
            <div
              className={`static border-t w-full pt-8 lg:w-fit lg:pt-0 lg:border-0 ${
                isFloating
                  ? "lg:fixed bottom-[80px] right-10"
                  : "lg:absolute right-10 bottom-[304px]"
              } `}
            >
              <div>{t("shareOn")}</div>
              <div className="inline-flex gap-4 mt-1 ">
                <FacebookShareButton
                  url={fullPath}
                  className="hover:scale-[1.1] hover:ring-2 ring-offset-1 rounded-full"
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={fullPath}
                  className="hover:scale-[1.1] hover:ring-2 ring-offset-1 rounded-full"
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={fullPath}
                  className="hover:scale-[1.1] hover:ring-2 ring-offset-1 rounded-full"
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

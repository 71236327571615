import Lottie from "lottie-react";
import nexiiotLoading from "assets/nexiiot-loading.json";

const Loading = ({ height = 100 }) => {
  return (
    <div className="h-screen flex justify-center items-center">
      <Lottie
        animationData={nexiiotLoading}
        style={{ height, margin: "20px 0" }}
        loop={true}
        autoplay={true}
      />
    </div>
  );
};

export default Loading;
